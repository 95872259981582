import { useEffect, useState } from "react";
// import "../styles/carouselStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Carousel = ({ images }) => {
  const [showArrows, setShowArrows] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowArrows(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let settings = {
    dots: true,
    // infinite: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: images.length > 1 && showArrows ? <NextArrow /> : null,
    prevArrow: images.length > 1 && showArrows ? <PrevArrow /> : null,
  };

  return (
    <div className="h-full w-full">
      <Slider {...settings}>
        {images.map((img) => (
          <div key={img.id} className="w-full">
            <Zoom>
              <img
                src={img.fileimage}
                alt={`Slide ${img.id}`}
                style={{
                  height: "auto",
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "500px",
                }}
              />
            </Zoom>
          </div>
        ))}
      </Slider>
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#264653",
        padding: "1.5rem",
        marginRight: "2rem",
        borderRadius: "999px",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#264653",
        padding: "1.5rem",
        marginLeft: "2rem",
        borderRadius: "999px",
        zIndex: 3,
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

// const Carousel = ({ images }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [direction, setDirection] = useState(null);

//   const slideVariants = {
//     hiddenRight: {
//       x: "100%",
//       opacity: 0,
//     },
//     hiddenLeft: {
//       x: "-100%",
//       opacity: 0,
//     },
//     visible: {
//       x: "0",
//       opacity: 1,
//       transition: {
//         duration: 1,
//       },
//     },
//     exit: {
//       opacity: 0,
//       scale: 0.9,
//       transition: {
//         duration: 0.5,
//       },
//     },
//   };
//   const slidersVariants = {
//     hover: {
//       // scale: 1.2,
//       backgroundColor: "#212529",
//     },
//   };
//   // const dotsVariants = {
//   //   initial: {
//   //     y: 0,
//   //   },
//   //   animate: {
//   //     y: -10,
//   //     scale: 1.2,
//   //     transition: { type: "spring", stiffness: 1000, damping: "10" },
//   //   },
//   //   hover: {
//   //     scale: 1.1,
//   //     transition: { duration: 0.2 },
//   //   },
//   // };

//   const handleNext = () => {
//     setDirection("right");
//     setCurrentIndex((prevIndex) =>
//       prevIndex + 1 === images.length ? 0 : prevIndex + 1
//     );
//   };

//   const handlePrevious = () => {
//     setDirection("left");

//     setCurrentIndex((prevIndex) =>
//       prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   const handleDotClick = (index) => {
//     setDirection(index > currentIndex ? "right" : "left");
//     setCurrentIndex(index);
//   };

//   return (
//     <div className="carousel">
//       <div className="carousel-images">
//         <AnimatePresence>
//           <img
//             key={currentIndex}
//             alt=""
//             src={images[currentIndex].fileimage}
//             initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
//             animate="visible"
//             exit="exit"
//             variants={slideVariants}
//             loading="lazy"
//             className="caro-img"
//           />
//           {/* <motion.div
//             key={currentIndex}
//             style={{
//               backgroundImage: `url(${images[currentIndex].fileimage})`,
//             }}
//             // src={images[currentIndex].fileimage}
//           //   initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
//           //   animate="visible"
//           //   exit="exit"
//           //   variants={slideVariants}
//           //   loading="lazy"
//           //   className="back-img-animate"
//           // />*/}
//         </AnimatePresence>
//         <div className="slide_direction">
//           <motion.div
//             variants={slidersVariants}
//             whileHover="hover"
//             className="left"
//             onClick={handlePrevious}
//           >
//             <FontAwesomeIcon icon={faChevronLeft} />
//           </motion.div>
//           <motion.div
//             variants={slidersVariants}
//             whileHover="hover"
//             className="right"
//             onClick={handleNext}
//           >
//             <FontAwesomeIcon icon={faChevronRight} />
//           </motion.div>
//         </div>
//       </div>
//       <div className="carousel-indicator">
//         {images.map((_, index) => (
//           <motion.div
//             key={index}
//             className={`dot ${currentIndex === index ? "activesDots" : ""}`}
//             onClick={() => handleDotClick(index)}
//             initial="initial"
//             animate={currentIndex === index ? "animate" : ""}
//             whileHover="hover"
//             // variants={dotsVariants}
//           ></motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };
export default Carousel;
