import { useState } from "react";
import ReactDOM from "react-dom";
import "../../styles/formModalStyles.css";
import { auth, db } from "../../config/firebaseSetup";
import {
  Timestamp,
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import AuctionForm from "./AuctionForm";
import Loading from "../Loader";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { v4 as uuid } from "uuid";
import fetchAndSendEmailTemplate from "../../utils/emailSend";
import { format } from "date-fns";
import { FIREBASE_URL } from "../../config/urlConfig";

const generateRandomTag = (length) => {
  const characters = uuid();
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

const CreateAuction = ({ onClose, getAllAuctions, userData }) => {
  const [showLoader, setShowLoader] = useState(false);
  const defaultValues = {};
  const currentUser = auth.currentUser;

  const handleSubmit = async (
    formData,
    methods,
    setSelectedFile,
    setTags,
    handleCancelDelete
  ) => {
    setShowLoader(true);

    if (formData?.images?.length > 24) {
      setShowLoader(false);
      toast.error("Only 24 images are allowed to be uploaded");
      return;
    }

    const endDateTime = new Date(`${formData.endDate}T${formData.endTime}`);
    const startDateTime = new Date(
      `${formData.startDate}T${formData.startTime}`
    );
    const endDateAndTime = Timestamp.fromDate(endDateTime);
    const startDateAndTime = Timestamp.fromDate(startDateTime);
    //use this function to store data;
    try {
      // Reference to the "Auction" collection
      const auctionsColRef = collection(db, "Auction");
      const uniquetag = generateRandomTag(4);
      // Create a new document with the entire formData
      const docRef = await addDoc(auctionsColRef, {
        created: serverTimestamp(),
        ...formData,
        status: "pending",
        uid: currentUser.uid,
        tag: uniquetag,
        userEmail: currentUser.email,
        serverEndTimeForAuction: endDateAndTime,
        serverStartTimeForAuction: startDateAndTime,
      });
      // console.log(formData);
      setShowLoader(false);
      toast.success("Auction Created Successfully");
      getAllAuctions();
      onClose();
      if (
        userData &&
        userData.notificationSettings.auctionRequestSubmitNotifications
      ) {
        fetchAndSendEmailTemplate(
          "For Auction Request",
          currentUser,
          `${FIREBASE_URL}/sendEmail`
        );
      }
      methods.reset();
      // console.log("Document written with ID:", docRef.id);
    } catch (error) {
      // console.error("Error adding document: ", error);
      // handleCancelDelete();
      setShowLoader(false);
      // setSelectedFile([]);
      // setTags([]);
      toast.error("An Error Occurred During Creating Your Auction");
    }
  };

  const handleDraft = async (draftData) => {
    setShowLoader(true);
    try {
      // Reference to the "Auction" collection
      const auctionsColRef = collection(db, "Auction");
      const uniquetag = generateRandomTag(4);
      // Create a new document with the entire draftData
      const docRef = await addDoc(auctionsColRef, {
        created: serverTimestamp(),
        uid: currentUser.uid,
        status: "draft",
        ...draftData,
        tags: draftData.tags ? draftData.tags : [],
        tag: uniquetag,
      });
      // console.log(draftData);
      toast.success("Auction Drafted Successfully");
      setShowLoader(false);
      if (getAllAuctions) {
        getAllAuctions();
      }

      // console.log("Document written with ID:", docRef.id);
    } catch (error) {
      // console.error("Error adding document: ", error);
      // console.log(draftData);

      toast.error("Error Drafting Auctions");
      setShowLoader(false);
      // onCancel();
    }
  };

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="formModal-container"
    >
      <div className="formModal-content">
        <h2>Create Your Auction</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <AuctionForm
        handleSubmit={handleSubmit}
        defaultValues={defaultValues}
        onCancel={onClose}
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        getAllAuctions={getAllAuctions}
        handleDraft={handleDraft}
      />
    </motion.div>,
    document.querySelector("#auctionCreateFormModal")
  );
};
export default CreateAuction;
