import AppRouter from "./routes/AppRouter";
import { useLocation } from "react-router-dom";
import { useUser } from "./context/UserContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthModal from './components/AuthModal';
import Header, { HeaderTwo } from "./components/Header";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import Overlay from "./components/Overlay";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import i18n from "./i18n/i18n";
import RestrictionsCard from "./components/RestrictionsCard";
import ScrollToTop from "./components/ScollToTop";
import ConversationList from "./components/messagingSystem/ConversationList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";

const App = () => {
  const { user, showLoginModal, showSignUpModal, closeAuthModalHandler } = useUser();
  const location = useLocation();
  const [ showConversationList, setShowConversationList ] = useState(false);
  // const isAdminRoute = location.pathname.startsWith("/admin");
  const conversationBoxRef = useRef();
  // const [ userLanguage, setUserLanguage ] = useState('');

  useEffect(() => {
    if (
      showLoginModal ||
      showSignUpModal
    ) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [
    showSignUpModal,
    showLoginModal
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        conversationBoxRef.current &&
        !conversationBoxRef.current.contains(event.target)
      ) {
        setShowConversationList(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const detectedLanguage = navigator.language.split('-')[ 0 ];
  //   if (detectedLanguage === 'it') {
  //     setUserLanguage('it');
  //   } else {
  //     setUserLanguage('en');
  //   }
  // }, []);

  // useEffect(() => {
  //   i18n.changeLanguage(userLanguage);
  // }, [ userLanguage ]);


  return (
    <div className="App">
      <ScrollToTop />
      {/* <BrowserRouter> */}
      {/* {!isAdminRoute && <Header />} */}
      <Header />
      <AppRouter user={user} />
      <AnimatePresence>
        {showLoginModal && <>
          <AuthModal>
            <Overlay onClose={closeAuthModalHandler} />
            <LoginPage />
          </AuthModal>
        </>}
      </AnimatePresence>
      <AnimatePresence>{showSignUpModal && <>
        <AuthModal>
          <Overlay onClose={closeAuthModalHandler} />
          <SignUpPage />
        </AuthModal>
      </>}</AnimatePresence>
      {/* </BrowserRouter> */}
      {/* <RestrictionsCard /> */}
      {user && <div ref={conversationBoxRef} className="conversationBoxContainer">
        {showConversationList &&
          <ConversationList />
        }
        <div className="chatIconToggle conversationBoxContainerToggle" onClick={() => setShowConversationList((prev) => !prev)}>
          <FontAwesomeIcon icon={faMessage} color="white" />
        </div>
      </div>
      }
      {/* <Chat /> */}
      <ToastContainer />
    </div>
  );
};

export default App;
