// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    ...require('./header/en.json'),
    ...require('./footer/en.json'),
    ...require('./newsletter/en.json'),
    ...require('./userprofile/en.json'),
    ...require('./auctionForm/en.json'),
    ...require('./auctionItem/en.json'),
    ...require('./completeProfile/en.json')
  },
  it: {
    ...require('./header/it.json'),
    ...require('./footer/it.json'),
    ...require('./newsletter/it.json'),
    ...require('./userprofile/it.json'),
    ...require('./auctionForm/it.json'),
    ...require('./auctionItem/it.json'),
    ...require('./completeProfile/it.json')
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
