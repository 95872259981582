
// export const requiredFields = [
//   'addressBillTo',
//   'addressShipping',
//   'billToAddress',
//   'cityBillTo',
//   'cityShipping',
//   'countryBillTo',
//   'countryShipping',
//   'postal-codeBillTo',
//   'postal-codeShipping',
//   'state-provinceBillTo',
//   'state-provinceShipping',
//   'paymentMethodAdded',
//   'lastName',
//   'firstName',
//   'nickname'
// ];

// export const requiredFieldsIfSameAddress = [
//   'addressBillTo',
//   'billToAddress',
//   'cityBillTo',
//   'countryBillTo',
//   'postal-codeBillTo',
//   'state-provinceBillTo',
//   'paymentMethodAdded',
//   'lastName',
//   'firstName',
//   'nickname'
// ];


// export const hasCompletedInformation = (user) => {
//   if (user.shippingAddressSameAsBilling) {
//     return requiredFieldsIfSameAddress.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== false && user[ field ] !== '');
//   } else {
//     return requiredFields.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== false && user[ field ] !== '');
//   }
// };

// export const getMissingFields = (user) => {
//   return requiredFields.filter(field => user[ field ] === undefined || user[ field ] === null || user[ field ] === false || user[ field ] === '');
// };


export const requiredFieldsTwo = [
  'addressBillTo',
  'addressShipping',
  'billToAddress',
  'cityBillTo',
  'cityShipping',
  'countryBillTo',
  'countryShipping',
  'postal-codeBillTo',
  'postal-codeShipping',
  'state-provinceBillTo',
  'state-provinceShipping',
  'paymentMethodAdded',

];

export const hasCompletedInformationForPopUp = (user) => {
  return requiredFieldsTwo.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
};


// export const shippingAddressRequiredFields = [
//   'addressShipping',
//   'cityShipping',
//   'countryShipping',
//   'postal-codeShipping',
//   'state-provinceShipping',
// ];

// export const billingAddressRequiredFields = [
//   'addressBillTo',
//   'billToAddress',
//   'cityBillTo',
//   'countryBillTo',
//   'postal-codeBillTo',
//   'state-provinceBillTo',
// ];

// export const cardField = [
//   'paymentMethodAdded',
// ];

// export const nameField = [
//   'lastName',
//   'firstName',
//   'nickname'
// ];

// export const shippingAddressCompleted = (user) => {
//   if (user.shippingAddressSameAsBilling) {
//     return true;
//   }
//   return shippingAddressRequiredFields.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
// };

// export const billingAddressCompleted = (user) => {
//   return billingAddressRequiredFields.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
// };

// export const cardCompleted = (user) => {
//   return cardField.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== false && user[ field ] !== '');
// };

// export const realNameCompleted = (user) => {
//   return nameField.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
// };




export const requiredFields = [
  'addressBillTo',
  'addressShipping',
  'billToAddress',
  'cityBillTo',
  'cityShipping',
  'countryBillTo',
  'countryShipping',
  'postal-codeBillTo',
  'postal-codeShipping',
  'state-provinceBillTo',
  'state-provinceShipping',
  'paymentMethodAdded',
  'lastName',
  'firstName',
  'nickname'
];

export const requiredFieldsIfSameAddress = [
  'addressBillTo',
  'billToAddress',
  'cityBillTo',
  'countryBillTo',
  'postal-codeBillTo',
  'state-provinceBillTo',
  'paymentMethodAdded',
  'lastName',
  'firstName',
  'nickname'
];

export const hasCompletedInformation = (user) => {
  if (user.shippingAddressSameAsBilling) {
    return requiredFieldsIfSameAddress.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== false && user[ field ] !== '');
  } else {
    return requiredFields.every(field => user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== false && user[ field ] !== '');
  }
};

export const getMissingFields = (user) => {
  const fieldsToCheck = user.shippingAddressSameAsBilling ? requiredFieldsIfSameAddress : requiredFields;
  return fieldsToCheck.filter(field => user[ field ] === undefined || user[ field ] === null || user[ field ] === false || user[ field ] === '');
};

export const shippingAddressRequiredFields = [
  'addressShipping',
  'cityShipping',
  'countryShipping',
  'postal-codeShipping',
  'state-provinceShipping',
];

export const billingAddressRequiredFields = [
  'addressBillTo',
  'billToAddress',
  'cityBillTo',
  'countryBillTo',
  'postal-codeBillTo',
  'state-provinceBillTo',
];

export const cardField = [
  'paymentMethodAdded',
];

export const nameField = [
  'lastName',
  'firstName',
  'nickname'
];

export const shippingAddressCompleted = (user) => {
  return user.shippingAddressSameAsBilling || shippingAddressRequiredFields.every(field =>
    user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
};

export const billingAddressCompleted = (user) => {
  return billingAddressRequiredFields.every(field =>
    user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
};

export const cardCompleted = (user) => {
  return cardField.every(field =>
    user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== false && user[ field ] !== '');
};

export const realNameCompleted = (user) => {
  return nameField.every(field =>
    user[ field ] !== undefined && user[ field ] !== null && user[ field ] !== '');
};