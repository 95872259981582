import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../config/firebaseSetup";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import Loading from "../components/Loader";
import '../styles/privacy-modal.css';
import { useFormCompletion } from "./FormCompletionContext";

const TermsAgreementContext = createContext(null);

export const TermsAgreementContextProvider = ({ children }) => {
  const [ showTermModal, setShowTermModal ] = useState(false);
  const { logout } = useUser();
  const { user, loading: isLoading } = useFormCompletion();
  const [ updating, setUpdating ] = useState(false);
  // const [ isLoading, setIsLoading ] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    // const initializeUserData = async () => {
    //   setIsLoading(true);
    //   try {
    //     const currentUser = auth.currentUser;
    //     if (currentUser && isMounted) {
    //       await getUserData();
    //     }
    //   } catch (error) {
    //     console.error("Error initializing user data:", error);
    //   } finally {
    //     if (isMounted) {
    //       setIsLoading(false);
    //     }
    //   }
    // };

    // initializeUserData();

    return () => {
      isMounted = false;
    };
  }, []);

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       await getUserData();
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const isTermsOrPrivacyPage = location.pathname === '/privacy-policy' ||
      location.pathname === '/term-of-service';

    if (isLoading) return;

    if (isTermsOrPrivacyPage) {
      setShowTermModal(false);
    } else if (user) {
      setShowTermModal(!user?.tosAndPpAgreed);
    }
  }, [ user, location.pathname, isLoading ]);

  const handleAgree = async () => {
    if (!user || updating) return;

    setUpdating(true);
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        tosAndPpAgreed: true,
        updatedAt: new Date().toISOString()
      });
      setShowTermModal(false);
      navigate('/profile');
    } catch (error) {
      console.error("Failed to update terms agreement:", error);
    } finally {
      setUpdating(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("loginUser");
    logout();
    auth.signOut();
    setShowTermModal(false);
    if (location.pathname === "/profile") {
      navigate("/");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <TermsAgreementContext.Provider value={{ showTermModal, handleLogout, handleAgree }}>
      {children}
      {showTermModal && user && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className="modal-overlayPp"
        >
          <div className="modal-contentPp">
            <h2 className="modal-titlePp">Agree to Terms</h2>

            <div className="modal-bodyPP">
              <label htmlFor="agree-tos-pp" className="modal-descriptionPP">
                I confirm that I have read and understood BidandGo.it terms and condition{" "}
                <button className="modal-linkPp" onClick={() => navigate("/term-of-service")}>
                  Terms of Service
                </button>{" "}
                and{" "}
                <button className="modal-linkPp" onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </button>
              </label>
            </div>

            <div className="modal-actionsPp">
              <button
                onClick={handleAgree}
                className={`modal-buttonPp modal-primaryPp ${ updating ? 'loadingPp' : '' }`}
                disabled={updating}
              >
                {updating ? <span className="loaderPp"></span> : 'Agree to Terms'}
              </button>


              <button onClick={handleLogout} className="modal-buttonPp modal-secondaryPp">
                Logout
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </TermsAgreementContext.Provider>

  );
};

export function useTermsAgreement () {
  const context = useContext(TermsAgreementContext);
  if (context === null) {
    throw new Error('useTermsAgreement must be used within a TermsAgreementProvider');
  }
  return context;
}