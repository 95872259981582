import { useState, useEffect } from "react";
import { auth, db } from "../../config/firebaseSetup";
import { Link } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import NewConversation from "./NewConversation";
import "../../styles/conversationBoxStyles.css";
import ConversationView from "./ConversationView";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import InlineLoader from "../InlineLoader";

const ConversationList = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [showConversation, setShowConversation] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!currentUser) return;

    try {
      setLoading(true);
      const conversationsCollection = collection(db, "conversations");
      const q = query(
        conversationsCollection,
        where("participants", "array-contains", currentUser.uid),
        orderBy("lastUpdated", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const conversations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConversations(conversations);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      setConversations([]);
      console.error("Failed to fetch conversations", error);
      setLoading(false);
    }
  }, [currentUser]);

  return (
    <div className="conversationsListContainer">
      <div className="newChatStart">
        <h2>Conversations</h2>
        {showConversation ? (
          <div>
            <Button
              className={"newChatButton"}
              onClick={() => setShowConversation(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </div>
        ) : (
          <NewConversation
            onNewChat={(conversation) => {
              setSelectedConversationId(conversation?.id);
              setShowConversation(true);
            }}
          />
        )}
      </div>
      <div style={{ position: "relative" }}>
        {loading ? (
          <InlineLoader />
        ) : conversations.length === 0 ? (
          <p className="no-messages">No Messages</p>
        ) : !showConversation ? (
          conversations.map((conversation) => {
            return (
              <div key={conversation.id} className="singleMessage">
                <button
                  onClick={() => {
                    setSelectedConversationId(conversation.id);
                    setShowConversation(true);
                  }}
                >
                  <span className="textWithEllipsis">
                    {" "}
                    {conversation?.mostRecentMessage?.message}
                  </span>
                  <div
                    className={`${
                      !conversation?.mostRecentMessage?.sentByAdmin
                        ? ""
                        : "unreadMessageFromUser"
                    }`}
                  ></div>
                </button>
              </div>
            );
          })
        ) : (
          <ConversationView conversationId={selectedConversationId} />
        )}
      </div>
    </div>
  );
};

export default ConversationList;
