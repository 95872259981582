import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Sidebar from "../components/Sidebar";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../components/Button";
import BillingCard from "../components/cards/BillingCard";
import EditProfile from "../components/cards/EditProfile";
import auctionIcon from "../assets/icons/auction.svg";
import heartIcon from "../assets/icons/favouriteHeart.svg";
import shippingIcon from "../assets/icons/shipping.svg";
import billingIcon from "../assets/icons/billing.svg";
import paymentIcon from "../assets/icons/payment-method.svg";
import editProfileIcon from "../assets/icons/edit-profile.svg";
import logoutIcon from "../assets/icons/logout.svg";
import exclamationIcon from "../assets/icons/exclamation.svg";
import tagIcon from "../assets/icons/tagIcon.svg";
import "../styles/userProfilePageStyle.css";
import UserAuctions from "../components/userAuctionsDashboard/UserAuctions";
import { useUser } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { auth, db } from "../config/firebaseSetup";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import RequestTagsForm from "../components/userAuctionsDashboard/RequestTagsForm";
import UserLikedAuctions from "../components/userAuctionsDashboard/UserLikedAuctions";
import UserBids from "../components/userAuctionsDashboard/UserBids";
import Loading from "../components/Loader";
import Overlay from "../components/Overlay";
import VerificationModal from "../components/VerificationModal";
import VerifyEmail from "../components/userAuctionsDashboard/VerifyEmail";
import VerifyNumber from "../components/userAuctionsDashboard/VerifyNumber";
import { useNavigate } from "react-router";
import notificationsIcon from "../assets/icons/notificationBell.svg";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { BecomeASeller } from "../components/userAuctionsDashboard/BecomeASeller";
import PaymentForm from "../components/cards/AddPaymentCard";
import CardDisplay from "../components/cards/CardDisplay";

import NotificationsSettings from "../components/userAuctionsDashboard/NotificationsSettings";
import fetchAndSendEmailTemplate from "../utils/emailSend";
import axios from "axios";
import { countries } from "../utils/countries";
import AddressCard from "../components/cards/AddessCard";
import {
  FormCompletionProvider,
  useFormCompletion,
} from "../context/FormCompletionContext";
import { agreeTosAndPpValidation } from "../utils/inputValidations";
import { FIREBASE_URL } from "../config/urlConfig";
// import firebase from "@firebase/app";

const ShippingTab = ({ user, getUser }) => {
  const [phoneNumber, setPhoneNumber] = useState(`${user.shippingPhoneNumber}`);
  const [isSameAddress, setIsSameAddress] = useState(
    user.shippingAddressSameAsBilling || false
  );

  const methods = useForm({
    defaultValues: async () => {
      try {
        if (!user) return {};

        // const userDocRef = doc(db, "users", currentUser.uid);
        // const docSnap = await getDoc(userDocRef);

        // return docSnap.data();
        //   if (isSameAddress) {
        //     return {
        //       addressShipping: user.addressBillTo || " ",
        //       apartmentShipping: " ",
        //       cityShipping: user.cityBillTo || " ",
        //       countryShipping: user.countryBillTo || " ",
        //       "postal-codeShipping": user["postal-codeBillTo"] || " ",
        //       "state-provinceShipping": user["state-provinceBillTo"],
        //       shippingAddressName: user.shippingAddressName,
        //     };
        //   } else {
        return user;
        //   }
      } catch (error) {
        return {};
      }
    },
  });

  // useEffect(() => {
  //   methods.reset({
  //     addressShipping: isSameAddress
  //       ? user.addressBillTo || ""
  //       : user.addressShipping || "",
  //     apartmentShipping: isSameAddress ? "" : user.apartmentShipping || "",
  //     cityShipping: isSameAddress
  //       ? user.cityBillTo || ""
  //       : user.cityShipping || "",
  //     countryShipping: isSameAddress
  //       ? user.countryBillTo || ""
  //       : user.countryShipping || "",
  //     "postal-codeShipping": isSameAddress
  //       ? user["postal-codeBillTo"] || ""
  //       : user["postal-codeShipping"] || "",
  //     "state-provinceShipping": isSameAddress
  //       ? user["state-provinceBillTo"]
  //       : user["state-provinceShipping"] || "",
  //     shippingAddressName: isSameAddress
  //       ? user.shippingAddressName
  //       : user.shippingAddressName || "",
  //   });
  // }, [isSameAddress, user]);

  const submitHandler = async () => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      const name = methods.getValues("shippingAddressName");
      if (isSameAddress) {
        await updateDoc(userDocRef, {
          shippingAddressSameAsBilling: true,
          // shippingAddressName: name || " ",
          // shippingPhoneNumber: phoneNumber,
        });
        toast.success("Profile updated successfully!");
        // getUser(user);
      } else {
        // console.log(isSameAddress);
        formData(userDocRef);
      }
    } catch (error) {}
  };

  const formData = methods.handleSubmit(async (formData, userDocRef) => {
    try {
      // console.log(formData);
      await updateDoc(userDocRef, {
        ...formData,
        shippingAddressSameAsBilling: false,
        shippingPhoneNumber: phoneNumber,
        paymentMethodAdded: user?.paymentMethodAdded,
      });
      toast.success("Profile updated successfully!");
      // getUser(user);
    } catch (error) {
      toast.error("Error updating profile");
    }
  });

  // const removeUserFromLocalStorage = () => {
  //   localStorage.removeItem("loginUser");
  // };
  return (
    <>
      {user ? (
        <div className="billing-tab-grid">
          <FormProvider {...methods}>
            <form
              className="billing-tab-form"
              action=""
              onSubmit={(e) => e.preventDefault()}
            >
              <AddressCard
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                isSameAddress={isSameAddress}
                setIsSameAddress={setIsSameAddress}
                // style={{ marginTop: "0", width: "100%", maxWidth: "700px" }}
                style={{ marginTop: "0", width: "100%" }}
              />
              <Button onClick={submitHandler}>Save Shipping Address</Button>
            </form>
          </FormProvider>
        </div>
      ) : (
        <h2>No user please login</h2>
      )}
    </>
  );
};

function stripePromise() {
  try {
    const stripe = loadStripe(
      "pk_test_51OUuKSGOqhTl3dZ4AblezxVuiTuCRZLwzGtXXWQ7B9qrvc9xPpfvxYgvEetDsrOiyLZuvRtvhGE9vP0SPe5CIKWP00Cigkeq7z"
    );
    return stripe;
  } catch (error) {
    console.log(error);
    return "";
  }
}

const stripeKey = stripePromise();

const PaymentTab = ({ user, getUser }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [cardId, setCardId] = useState(user.paymentMethodId || null);
  const [haveCards, setHaveCards] = useState(0);
  const currentUser = auth.currentUser;

  const paymentSubmitHandler = async (paymentMethodId = null) => {
    try {
      // setShowLoader(true);
      const userDocRef = doc(db, "users", currentUser.uid);
      let updatePayload = {
        paymentMethodAdded: true,
      };

      if (paymentMethodId && !user.paymentMethodId) {
        updatePayload = {
          ...updatePayload,
          paymentMethodId: paymentMethodId,
        };
      }

      await updateDoc(userDocRef, updatePayload);
      toast.success("Payment method added successfully!");

      setShowLoader(false);
      setShowCardForm(false);
      // await getUser(user);
      if (user && user.notificationSettings.creditAddedNotifications) {
        fetchAndSendEmailTemplate(
          "For Credit Added Success",
          user,
          `${FIREBASE_URL}/sendEmail`
        );
      }
    } catch (error) {
      toast.error("Error adding Payment method");
      setShowLoader(false);
    }
  };

  const cardIdHandler = async () => {
    setShowLoader(true);
    try {
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        paymentMethodId: cardId,
      });
      toast.success("Payment card changed successfully!");
      setShowLoader(false);
      // getUser(user);
    } catch (error) {
      toast.success("Error Payment card changing!");
      setShowLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      {showLoader && <Loading />}
      <Button
        className={"addNewCard"}
        onClick={() => setShowCardForm(!showCardForm)}
      >
        Add Card
      </Button>
      {showCardForm && (
        <>
          <Elements stripe={stripeKey}>
            <PaymentForm
              clientSecret={user.customerClientKey}
              paymentSubmitHandler={paymentSubmitHandler}
              user={user}
              setShowLoader={setShowLoader}
              setShowCardForm={setShowCardForm}
            />
          </Elements>
        </>
      )}
      {user && user.paymentMethodAdded ? (
        <CardDisplay
          setCardId={setCardId}
          // getUser={getUser}
          cardId={cardId}
          user={user}
          setShowLoader={setShowLoader}
          setHaveCards={setHaveCards}
        />
      ) : null}
      {haveCards > 1 ? (
        <div className="saveChangesCard">
          <Button onClick={cardIdHandler}>Save Card</Button>
        </div>
      ) : null}
    </>
  );
};

const BillingTab = ({ user, getUser }) => {
  const methods = useForm({
    defaultValues: async () => {
      try {
        if (!user) return {};
        return user;
      } catch (error) {
        return {};
      }
    },
  });

  const submitHandler = methods.handleSubmit(async (formData) => {
    try {
      const userDocRef = doc(db, "users", user.uid);

      if (user.shippingAddressSameAsBilling) {
        const fullName = `${user.firstName} ${user.lastName}`;
        const name = fullName.includes("undefined") ? "" : fullName;

        let sameShippingAndBilling = {
          addressShipping: formData.addressBillTo || " ",
          apartmentShipping: " ",
          cityShipping: formData.cityBillTo || " ",
          countryShipping: formData.countryBillTo || " ",
          "postal-codeShipping": formData["postal-codeBillTo"] || " ",
          "state-provinceShipping": formData["state-provinceBillTo"],
          shippingAddressName: name,
        };
        await updateDoc(userDocRef, {
          ...formData,
          ...sameShippingAndBilling,
          paymentMethodAdded: user?.paymentMethodAdded,
        });
      } else {
        await updateDoc(userDocRef, {
          ...formData,
          paymentMethodAdded: user?.paymentMethodAdded,
        });
      }

      toast.success("Profile updated successfully!");
      // getUser(user);
    } catch (error) {
      console.log(error);
      toast.error("Error updating profile");
    }
  });

  return (
    <>
      {user ? (
        <div className="billing-tab-grid">
          <FormProvider {...methods}>
            <form
              className="billing-tab-form"
              action=""
              onSubmit={(e) => e.preventDefault()}
            >
              <BillingCard
                // style={{ marginTop: "0", width: "100%", maxWidth: "700px" }}
                style={{ marginTop: "0", width: "100%" }}
              />
              <Button onClick={submitHandler}>Save Billing Address</Button>
            </form>
          </FormProvider>
        </div>
      ) : (
        <h2>No user please login</h2>
      )}
    </>
  );
};

const EditProfileTab = ({ user, getUser }) => {
  const [nationality, setNationality] = useState(countries[0]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showNumberModal, setShowNumberModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(`+${user.phoneNumber}`);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: async () => {
      try {
        return {
          ...user,
          "agree-tos-pp": user?.tosAndPpAgreed,
          // };
        };
      } catch (error) {
        return {};
      }
    },
  });

  useEffect(() => {
    if (user) {
      methods.reset({
        "agree-tos-pp": user?.tosAndPpAgreed,
      });
    }
  }, [user]);

  // Check for duplicate or already in use phone number
  const isPhoneNumberInUse = async (phoneNumber) => {
    const usersRef = collection(db, "users");
    const q = await query(usersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const currentUser = auth.currentUser;

  const submitHandler = methods.handleSubmit(async (formData) => {
    let cleanedPhoneNumber = "";

    if (phoneNumber) {
      cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    }

    const phoneNumberChanged = cleanedPhoneNumber !== user.phoneNumber;

    if (phoneNumberChanged && cleanedPhoneNumber) {
      const phoneNumberInUse = await isPhoneNumberInUse(cleanedPhoneNumber);

      if (phoneNumberInUse) {
        toast.error("Phone number is already in use");
        return;
      }
    }

    try {
      setShowLoader(true);
      // Check for nickname if nickname is available
      if (user.nickname === null) {
        const nicknameRef = collection(db, "users");
        const nicknameQuery = query(
          nicknameRef,
          where("nickname", "==", formData.nickname)
        );

        const querySnapshot = await getDocs(nicknameQuery);

        if (!querySnapshot.empty) {
          setShowLoader(false);
          toast.error(
            "Nickname is already taken, please choose a different one."
          );
          return;
        }
      }

      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        ...formData,
        phoneNumber: cleanedPhoneNumber,
        nationality: user.nationality ? user.nationality : nationality,
        paymentMethodAdded: user?.paymentMethodAdded,
        tosAndPpAgreed: formData["agree-tos-pp"],
      });

      toast.success("Profile updated successfully!");
      // await getUser(user);

      setShowLoader(false);
      const fullName = `${formData.firstName} ${formData.lastName}`;

      try {
        await axios.post(`${FIREBASE_URL}/updateCustomer`, {
          customerId: user.customerId,
          name: fullName,
        });
      } catch (error) {
        console.log({ error: "failed to update user" });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error updating profile");
      setShowLoader(false);
    }
  });

  const codeVerifyHandler = methods.handleSubmit((data) => {
    console.log(data);
  });
  const codeEmailVerifyHandler = methods.handleSubmit((data) => {
    console.log(data);
  });

  return (
    <>
      {showLoader && <Loading />}
      {currentUser ? (
        <div className="billing-tab-grid">
          <FormProvider {...methods}>
            <form
              className="billing-tab-form"
              action=""
              onSubmit={(e) => e.preventDefault()}
            >
              <EditProfile
                // style={{ marginTop: "0", width: "100%", maxWidth: "700px" }}
                style={{ marginTop: "0", width: "100%" }}
                userNationality={methods.getValues("nationality")}
                setNationality={setNationality}
                control={methods.control}
                setShowEmailModal={setShowEmailModal}
                setShowNumberModal={setShowNumberModal}
                user={user}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
              <div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "-2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <input
                    {...methods.register("agree-tos-pp")}
                    type="checkbox"
                    name="agree-tos-pp"
                    id="agree-tos-pp"
                  />
                  <label
                    htmlFor="agree-tos-pp"
                    className={` ${
                      methods?.formState?.errors["agree-tos-pp"]?.message &&
                      "input-field-error"
                    }`}
                  >
                    I confirm that I have read and understood BidandGo.it terms
                    and condition{" "}
                    <button
                      className="agree-link"
                      onClick={() => {
                        navigate("/term-of-service");
                      }}
                    >
                      Terms of Service
                    </button>
                    and{" "}
                    <button
                      className="agree-link"
                      onClick={() => {
                        navigate("/privacy-policy");
                      }}
                    >
                      Privacy Policy
                    </button>
                  </label>
                </div>
                <p className="input-field-error">
                  {methods?.formState?.errors["agree-tos-pp"]?.message}
                </p>
              </div>
              <Button onClick={submitHandler}>Update Profile</Button>
            </form>
          </FormProvider>
          {showEmailModal && (
            <FormProvider {...methods}>
              <form onSubmit={(e) => e.preventDefault()}>
                <>
                  <Overlay onClose={() => setShowEmailModal(false)} />
                  <VerificationModal>
                    <VerifyEmail
                      // control={control}
                      onClose={() => setShowEmailModal(false)}
                      onSubmit={codeEmailVerifyHandler}
                    />
                  </VerificationModal>
                </>
              </form>
            </FormProvider>
          )}
          {showNumberModal && (
            <FormProvider {...methods}>
              <form onSubmit={(e) => e.preventDefault()}>
                <>
                  <Overlay onClose={() => setShowNumberModal(false)} />
                  <VerificationModal>
                    <VerifyNumber
                      // control={control}
                      onClose={() => setShowNumberModal(false)}
                      onSubmit={codeVerifyHandler}
                    />
                  </VerificationModal>
                </>
              </form>
            </FormProvider>
          )}
        </div>
      ) : (
        <h2>No user please login</h2>
      )}
    </>
  );
};

const LogOutTab = () => {
  const { logout } = useUser();
  const user = auth.currentUser;
  const navigate = useNavigate();

  return (
    <>
      {user ? (
        <div className="logout-tab">
          <h3>Are you sure you want to logout?</h3>
          <Button
            onClick={() => {
              localStorage.removeItem("loginUser");
              logout();
              auth.signOut();
              navigate("/");
            }}
          >
            Log out
          </Button>
        </div>
      ) : (
        <span>LOGIN</span>
      )}
    </>
  );
};

const PersonalInfo = ({ user, getUser }) => {
  return (
    <div className="personalInfoContainer">
      <div id="personal-info">
        <EditProfileTab
          user={user}
          // getUser={getUser}
        />
      </div>
      <div id="billing">
        <BillingTab
          user={user}
          // getUser={getUser}
        />
      </div>
      <div id="shipping">
        <ShippingTab
          user={user}
          // getUser={getUser}
        />
      </div>
      <div id="payment" className="paymentCard-div">
        <h3>Payment information</h3>
        <PaymentTab
          user={user}
          // getUser={getUser}
          style={{ marginTop: 0 }}
        />
      </div>
    </div>
  );
};

const UserProfilePage = () =>
  // { user, getUser }
  {
    const { t } = useTranslation("userProfile");
    const { user } = useFormCompletion();

    if (!user) {
      return;
    }

    const tabsList = [
      {
        label: t("tabs.myBids"),
        content: <UserBids heading={t("bidHeading")} />,
        icon: auctionIcon,
      },
      {
        label: t("tabs.favorites"),
        content: <UserLikedAuctions heading={t("favHeading")} />,
        icon: heartIcon,
      },
      // {
      //   label: t("tabs.shipping"),
      //   icon: shippingIcon,
      //   content: <ShippingTab user={user} getUser={getUser} />,
      // },
      // {
      //   label: t("tabs.billing"),
      //   content: <BillingTab user={user} getUser={getUser} />,
      //   icon: billingIcon,
      // },
      // {
      //   label: t("tabs.paymentMethods"),
      //   content: (
      //     <PaymentTab user={user} getUser={getUser} style={{ marginTop: 0 }} />
      //   ),
      //   icon: paymentIcon,
      // },
      {
        label: "Personal Info",
        // content: <EditProfileTab user={user} getUser={getUser} />,
        content: (
          <PersonalInfo
            user={user}
            // getUser={getUser}
          />
        ),
        icon: editProfileIcon,
      },
      // {
      //   label: t("tabs.editProfile"),
      //   content: <EditProfileTab user={user} getUser={getUser} />,
      //   icon: editProfileIcon,
      // },
      getSellerOrBecomeSellerTabData(),
      // user.isASeller
      //   ? {
      //       label: t("tabs.myAuctions"),
      //       content: <UserAuctions userData={user} />,
      //       icon: auctionIcon,
      //     }
      //   : user.status === "pending"
      //   ? {
      //       label: t("tabs.becomeASeller"),
      //       content: <span>Your request to become a seller is pending</span>,
      //       icon: exclamationIcon,
      //     }
      //   : {
      //       label: t("tabs.becomeASeller"),
      //       content: <BecomeASeller user={user} getUser={getUser} />,
      //       icon: exclamationIcon,
      //     },
      //     : user.status === "suspended"
      //   ? {
      //       label: "Account Suspended",
      //       content: <span>Your Account Has been Suspended</span>,
      //       icon: exclamationIcon,
      //     }
      //   : {
      //       label: t("tabs.becomeASeller"),
      //       content: <BecomeASeller user={user} getUser={getUser} />,
      //       icon: exclamationIcon,
      //     },
      {
        label: t("tabs.requestTags"),
        content: <RequestTagsForm userData={user} />,
        icon: tagIcon,
      },
      {
        label: "Notifications",
        content: (
          <NotificationsSettings
            user={user}
            // getUser={getUser}
          />
        ),
        icon: notificationsIcon,
      },
      {
        label: t("tabs.logOut"),
        content: <LogOutTab />,
        icon: logoutIcon,
      },
    ];

    function getSellerOrBecomeSellerTabData() {
      if (user.isASeller) {
        return {
          label: t("tabs.myAuctions"),
          content: <UserAuctions userData={user} />,
          icon: auctionIcon,
        };
      } else if (user.status === "pending") {
        return {
          label: t("tabs.becomeASeller"),
          content: <span>Your request to become a seller is pending</span>,
          icon: exclamationIcon,
        };
      } else if (user.status === "suspended") {
        return {
          label: "Account Suspended",
          content: <span>Your Account Has been Suspended</span>,
          icon: exclamationIcon,
        };
      } else {
        return {
          label: t("tabs.becomeASeller"),
          content: (
            <BecomeASeller
              user={user}
              // getUser={getUser}
            />
          ),
          icon: exclamationIcon,
        };
      }
    }

    return (
      <div>
        {/* <Header /> */}
        <div className="user-profile-sidebar">
          {/* <FormCompletionProvider> */}
          <Sidebar
            user={user}
            tabsList={tabsList}
            activeTab={0}
            // onClick={getUser}
          />
          {/* </FormCompletionProvider> */}
        </div>
        <Newsletter />
        <Footer color={"black"} />
      </div>
    );
  };

export default UserProfilePage;
