import { faComment, faTag } from "@fortawesome/free-solid-svg-icons";
import euro from '../assets/icons/euro.svg';

const usernameValidation = {
  required: {
    value: true,
    message: "required",
  },
};


const addressValidation = {
  required: {
    value: true,
    message: "address is required",
  },
};

export const addressNameValidation = {
  required: {
    value: true,
    message: "name is required",
  },
};


const cityValidation = {
  required: {
    value: true,
    message: "city is required",
  },
};


const provinceValidation = {
  required: {
    value: true,
    message: "province is required",
  },
};

const emailValidation = {
  required: {
    value: true,
    message: "email is required",
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "not valid email",
  },
};

const passwordValidation = {
  required: {
    value: true,
    message: "password required",
  },
  minLength: {
    value: 6,
    message: "min 6 characters",
  },
};

export const otpValidation = {
  required: {
    value: true,
    message: "required",
  },
  minLength: {
    value: 6,
    message: "min 6 characters",
  },
  maxLength: {
    value: 6,
    message: "max 6 characters",
  },
};

const currencyValidation = {
  required: {
    value: true,
    message: "currency is required",
  },
  min: {
    value: 10,
    message: "Minimum value is $10",
  },
  pattern: {
    value: /^\d+(\.\d+)?$/,
    message: "Please enter a valid positive number greater than or equal to 10",
  },
  // pattern: {
  //   value: /^(?!-)\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
  //   message: "Negative values not allowed",
  // },
};

const postalCodeValidation = {
  required: {
    value: true,
    message: "Postal code is required",
  },
  pattern: {
    // value: /^\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
    value: /^[\w\d\s-]+$/,
    message: "Invalid postal code",
  },
};

const creditCardValidation = {
  validate: {
    validCard: (cardNumber) => {
      const sanitizedCardNumber = cardNumber.replace(/[-\s]/g, "");

      if (/^\d{13,19}$/.test(sanitizedCardNumber)) {
        return true;
      }

      return "card number must be between 13, 19 digits";
    },
  },
};

const expirationDateValidation = {
  validate: {
    validExpirationDate: (expirationDate) => {
      const expirationDatePattern = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;

      if (!expirationDatePattern.test(expirationDate)) {
        return "Invalid expiration date. Please use the format MM/YY.";
      }

      const [ month, year ] = expirationDate.split('/');

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const expirationYear = parseInt(year, 10);

      if (expirationYear < currentYear) {
        return "The card has expired.";
      } else if (expirationYear === currentYear && parseInt(month, 10) < currentDate.getMonth() + 1) {
        return "The card has expired.";
      }

      return true;
    },
  },
  required: {
    value: true,
    message: "expiration date is required",
  },
};

const cvvValidation = {
  validate: {
    validCvv: (cvv) => {
      const cvvPattern = /^\d{3,4}$/;

      if (!cvvPattern.test(cvv)) {
        return "Invalid CVV/CVC. Please enter 3 or 4 digits.";
      }

      return true;
    },
  },
  required: {
    value: true,
    message: "CVV/CVC is required"
  }
};


export const titleInput = {
  name: "auctionTitle",
  label: "Auction Title English",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Title is required",
    },
    id: "auctionTitle",
    placeholder: "My Item Name",
  },
};

export const titleInputItalian = {
  name: "auctionTitleItalian",
  label: "Auction Title Italian",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Title is required",
    },
    id: "auctionTitleItalian",
    placeholder: "My Item Name",
  },
};

export const subjectInput = {
  name: "reportSubject",
  label: "Subject",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Subject is required",
    },
    id: "reportSubject",
    placeholder: "Subject",
  },
};



export const descriptionEnglish = {
  label: "Auction Description English",
  type: "text",
  id: "description-english",
  name: "descriptionEnglish",
  placeholder: "Write Description in English",
  validationRules: {
    required: {
      value: true,
      message: "Description english is required",
    },
  },
  multiline: true,
};
export const reportDescription = {
  label: "Report Description",
  type: "text",
  id: "reportDescription",
  name: "reportDescription",
  placeholder: "Write Description",
  validationRules: {
    required: {
      value: true,
      message: "Report Description Required",
    },
  },
  multiline: true,
};


export const editReqDescription = {
  label: "Description",
  type: "text",
  id: "reqqDescription",
  name: "reqDescription",
  placeholder: "Write Message",
  validationRules: {
    required: {
      value: true,
      message: "Message Required",
    },
  },
  multiline: true,
};

export const editSubjectInput = {
  name: "editReqSubject",
  label: "Subject",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Subject is required",
    },
    id: "editReqSubject",
    placeholder: "Subject",
  },
};

export const descriptionItalian = {
  label: "Auction Description Italian",
  type: "text",
  id: "description-italian",
  name: "descriptionItalian",
  placeholder: "Write Description in Italian",
  validationRules: {
    required: {
      value: true,
      message: "Description italian is required",
    },
  },
  multiline: true,
};

export const reservePrice = {
  type: "number",
  label: "Auction Reserve Price",
  id: "auctionReservePrice",
  name: "auctionReservePrice",
  placeholder: "100",
  icon: euro,
  validationRules: {
    required: {
      value: true,
      message: "Auction Reserve Price is required",
    },
    validate: {
      isNumber: (value) => !isNaN(value) || "Please enter a valid number",
      minValue: (value) =>
        value >= 0 || "Auction Reserve Price cannot be negative",
    },
  },
};


export const startingPrice = {
  type: "number",
  label: "Auction Starting Price",
  id: "auctionStartingPrice",
  name: "auctionStartingPrice",
  placeholder: "100",
  icon: euro,
  validationRules: {
    required: {
      value: true,
      message: "Auction Starting Price is required",
    },
    validate: {
      isNumber: (value) => !isNaN(value) || "Please enter a valid number",
      minValue: (value) =>
        value >= 0 || "Auction Starting Price cannot be negative",
    },
  },
};

export const tagValidation = {
  type: "text",
  label: "Tag",
  id: "tagInput",
  name: "tagInput",
  placeholder: "Enter tag",
  icon: faTag,
  validationRules: {
    required: "Tag is required",
    minLength: {
      value: 5,
      message: "Tag must be at least 5 characters long",
    },
    maxLength: {
      value: 50,
      message: "Tag cannot exceed 50 characters",
    },
    pattern: {
      value: /^[A-Z0-9 ]+$/,
      message: "Tag can only contain uppercase letters, numbers.",
    },
  },
};

export const categoryValidation = {
  type: "text",
  label: "Category",
  id: "categoryInput",
  name: "categoryName",
  placeholder: "Enter Category",
  icon: faTag,
  validationRules: {
    required: "Category is required",
    minLength: {
      value: 3,
      message: "Category must be at least 3 characters long",
    },
    maxLength: {
      value: 30,
      message: "Category cannot exceed 30 characters",
    },
    pattern: {
      value: /^[A-Za-z0-9\s]+$/,
      message: "Category can only contain letters, numbers, and spaces",
    },
  },
};

export const subCategoryValidation = {
  type: "text",
  label: "Sub Category",
  id: "subCategoryInput",
  name: "subCategoryName",
  placeholder: "Enter Sub Category",
  icon: faTag,
  validationRules: {
    required: "Sub Category is required",
    minLength: {
      value: 3,
      message: "Category must be at least 3 characters long",
    },
    maxLength: {
      value: 30,
      message: "Category cannot exceed 30 characters",
    },
    pattern: {
      value: /^[A-Za-z0-9\s]+$/,
      message: "Category can only contain letters, numbers, and spaces",
    },
  },
};


export const commentValidation = {
  type: "text",
  label: "Short Description",
  id: "commnetTag",
  multiline: true,
  name: "commentTag",
  placeholder: "Please provide a brief description or any additional details about the tag you'd like to request.",
  icon: faComment,
  validationRules: {
    required: "Description is required",
    maxLength: {
      value: 300,
      message: "Comment cannot exceed 300 characters",
    },
  },
};



export const editTagValidation = {
  type: "text",
  id: "tagInput",
  name: "editTagInput",
  placeholder: "Enter tag",
  validationRules: {
    required: "Tag is required",
    minLength: {
      value: 2,
      message: "Tag must be at least 2 characters long",
    },
    maxLength: {
      value: 20,
      message: "Tag cannot exceed 20 characters",
    },
    pattern: {
      value: /^[a-zA-Z0-9]+$/,
      message: "Tag can only contain letters and numbers",
    },
  },
};

const agreeTosAndPpValidation = {
  required: {
    value: true,
    message: "required",
  },
};



export {
  emailValidation, agreeTosAndPpValidation,
  passwordValidation,
  cvvValidation,
  expirationDateValidation,
  creditCardValidation,
  postalCodeValidation,
  provinceValidation,
  usernameValidation, currencyValidation,
  addressValidation, cityValidation
};